.cookies-container{
    border: 5px solid #000;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    .cookies-text{
        padding: 10px;
        text-align: center;
    }
}