.spartan-navbar {
    width: 100vw;
    height: 75px;
    background-color: rgba(0,0,0,0);
    position: absolute;

    .logo-container{
        width: 200px;
        margin: 0 auto;
        height: 75px;
        padding-top: 20px;
    }

    .organization-container{
        width: 200px;
        position: absolute;
        top:25px;
        left:10px;

        select {
            background-color: rgba(0,0,0,0);
            color: #fff;
            border: none;

            option {
                background-color: #000;
            }
        }
    }

    .user-menu-container{
        width: 200px;
        position: absolute;
        top:25px;
        right:10px;

        .user-welcome{
            color: #fff;
            cursor: pointer;
        }

        .user-menu {
            width: 200px;
            position: fixed;
            top: 75px;
            right: 10px;
            z-index: 10000;
            color: #fff;
            background-color: #000;

            .user-menu-item{
                padding: 10px;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background-color: #69CBF9;
                }
            }
        }
    }
}

