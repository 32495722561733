.spartan-text-input-container{
    background-color: #04242C;
    padding: 12px;

    .label {
        font-family: "Aventa", sans-serif;
        color: #82BFCF;
        text-transform:uppercase;
        font-size: 12px;
        font-weight: 600;
    }

    input {
        border: none;
        background-color: #06313b;
        color: #82BFCF;
        width: 100%;
    }
}

.spartan-button{
    border: none;
    width: 100%;
    height: 70px;
    font-family: "Aventa", sans-serif;
    background-color: #69CBF9;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.spartan-form-group-container{
    background-color: #04242C;
    padding: 12px;
    color: #fff;
}